import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import React, { createContext, SyntheticEvent, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter, NavigateFunction, Route, Routes, useNavigate } from 'react-router-dom';
import { HomePageContainer } from './pages/home/home';
import { EditPageContainer } from './pages/edit/edit';
import { DomainContainer } from './pages/domain/domain';
import { DomainEditContainer } from './pages/domain_edit/domain_edit';
import { DomainAddContainer } from './pages/domain_add/domain_add';

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <HomePageContainer />,
//   },
//   { path: "/edit?slug=:slug", element: <EditPageContainer /> },
// ]);

const App = () => {
	useEffect(() => {
		if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
			document.documentElement.setAttribute('data-bs-theme', 'dark');
		}
	}, []);

	return (
		<Routes>
			<Route path="/" element={<HomePageContainer />} />
			<Route path="/x/edit" element={<EditPageContainer />} />
			<Route path="/x/domain" element={<DomainContainer />} />
			<Route path="/x/domain_edit" element={<DomainEditContainer />} />
			<Route path="/x/domain_add" element={<DomainAddContainer />} />
		</Routes>
	);
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// <React.StrictMode>
// </React.StrictMode>
//   {/* <BrowserRouter basename="home"> */}
root.render(
	<BrowserRouter>
		<NavigationProvider>
			<App />
		</NavigationProvider>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
const NavigationContext = createContext<NavigateFunction | null>(null);

function NavigationProvider({ children }: { children: React.ReactNode }) {
	const navigate = useNavigate();
	return <NavigationContext.Provider value={navigate}>{children}</NavigationContext.Provider>;
}

function useGlobalNavigate() {
	const navigate = useContext(NavigationContext);
	if (!navigate) throw new Error('useGlobalNavigate must be used within a NavigationProvider');
	return navigate;
}

export type TRouteTo = (e: SyntheticEvent | false, route: string) => void;

export function useRouteTo() {
	const navigate = useGlobalNavigate();

	return (e: SyntheticEvent | false, route: string) => {
		if (e) e.preventDefault();
		navigate(route);
	};
}
