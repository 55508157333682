import { HInput } from 'df-react-model/dist/react-model-ui/form/h-input';
import { ReactModel } from 'df-react-model/dist/react-model/react-model';
import { useEffect } from 'react';
import styles from './navbar.module.scss';

import { TOneDomain } from '../api/types';

import clsx from 'clsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { api_submitNewUrl } from '../api/url-add';
import { logout } from '../keylockr/logout';

import { api_loadDomains } from '../api/domains-list';
import { api_loadUser } from '../api/load-user';
import { loginLogic } from '../keylockr/login';
import { QRCodeSVG } from 'qrcode.react';
import { model_user } from '../keylockr/sso-init';
import { useRouteTo } from '..';
import { loadPrivDomainList } from '../models/domain';
import { loadUrlList } from '../models/url';

type TModelDomainList = {
	list: TOneDomain[];
};

type TNewUrlForm = {
	domain: TOneDomain | undefined;
	url: string;
};

export const model_domain_list = new ReactModel<TModelDomainList>({
	model: {
		list: [],
	},
});

const model_new_url_form = new ReactModel<TNewUrlForm>({
	model: {
		domain: undefined,
		url: '',
	},
});

const qrcode_modal_id = 'qrcode_modal';

async function init() {
	try {
		// Load user
		let is_guest = false;
		if (!model_user.s.user_id) is_guest = true;
		if (is_guest) {
			await api_loadUser();
			if (model_user.s.user_id) {
				await loadUrlList();
				await loadPrivDomainList();
			}
		}

		// load available domains
		const list = await api_loadDomains();
		model_domain_list.setProperty({ list });
		if (!model_new_url_form.s.domain) {
			model_new_url_form.setProperty({ domain: list[0] });
		}
	} catch (e) {
		if (!(e instanceof Error)) {
			toast.warn('Failed to load user info or domain list');
			return;
		}
		toast(e.message);
	}
}

export function NavBar() {
	useEffect(() => {
		init();
	}, []);

	model_user.useModel();
	model_domain_list.useModel();
	model_new_url_form.useModel();

	return (
		<>
			<div className={styles.nav_bar_container}>
				<div className={styles.nav_bar}>
					<Logo />
					<NewUrlInput />
					<UserInfo />
				</div>
			</div>
			<ToastContainer position="top-right" />
			<QRCodeDialog />
		</>
	);
}

function UserInfo() {
	const routeTo = useRouteTo();
	if (!model_user.s.user_id)
		return (
			<div className={styles.right_spacer}>
				<div className={styles.login_btn_con}>
					<button
						onClick={async () => {
							loginLogic(qrcode_modal_id);
						}}
					>
						Login
					</button>
				</div>
			</div>
		);
	return (
		<div className={styles.right_spacer}>
			<p>
				<button className="btn btn-link" onClick={(e) => routeTo(e, `/x/domain`)}>
					My Domains
				</button>
			</p>
			<div className={styles.user_profile}>{model_user.s.user_nickname.substring(0, 2)}</div>
			<p>{model_user.s.user_id}</p>
			<button type="button" className="btn btn-danger" onClick={logout}>
				logout
			</button>
		</div>
	);
}

function Logo() {
	return (
		<div className={styles.left_spacer}>
			<a href="/" className="navbar-brand fs-2">
				<i className="fa-solid fa-check-double me-1"></i>
				OKUrl
			</a>
		</div>
	);
}

function QRCodeDialog() {
	return (
		<div className={clsx('modal', styles.qrcode_modal)} id={qrcode_modal_id}>
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Login via KeyLockr</h5>
					</div>
					<div className="modal-body">
						{model_user.s.sso_link ? (
							<>
								<p>Scan the QR Code with KeyLockr to login and access advanced features!</p>
								<QRCodeSVG style={{ border: '2px solid white' }} value={model_user.s.sso_link} />
							</>
						) : (
							<button type="button" className="btn btn-secondary">
								Refresh QR Code
							</button>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

// Nav bar center component with submit function.
function NewUrlInput() {
	const routeTo = useRouteTo();
	if (!model_domain_list.s.list.length) return <div className={clsx(styles.center_plain)}></div>;

	let items: JSX.Element[] =
		model_domain_list.s.list.map((item) => {
			const changeDomain = () => {
				model_new_url_form.setProperty({ domain: item });
			};
			return (
				<li key={item.id}>
					<button className={clsx('dropdown-item', model_new_url_form.s.domain?.id === item.id ? 'active' : '')} onClick={changeDomain}>
						{item.domain}
					</button>
				</li>
			);
		}) ?? [];

	const onSubmit = async () => {
		try {
			await api_submitNewUrl(model_new_url_form.s.url, model_new_url_form.s.domain, routeTo);
			model_new_url_form.setProperty({ url: '' });
		} catch (e) {
			if (!(e instanceof Error)) {
				toast.warn('[N131] An internal error occurred, please refresh page.');
				return;
			}
			toast.warn(e.message);
		}
	};

	return (
		<div className={clsx(styles.center)}>
			<button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
				{model_new_url_form.s.domain?.domain}
			</button>
			<ul className="dropdown-menu">{items}</ul>
			<HInput
				className="form-control"
				model={model_new_url_form}
				field="url"
				placeholder="Paste the target link here and enter"
				autoFocus={true}
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						onSubmit();
					}
				}}
			/>
			<SubmitButton onSubmit={onSubmit} />
		</div>
	);
}

function SubmitButton(props: { onSubmit: () => any }) {
	if (model_new_url_form.s.url) {
		return (
			<div className={styles.submit_btn} onClick={props.onSubmit}>
				<i className="text-primary fa-solid fa-circle-plus"></i>
			</div>
		);
	} else {
		return (
			<div className={styles.submit_btn}>
				<i className="text-secondary fa-thin fa-circle-plus"></i>
			</div>
		);
	}
}
