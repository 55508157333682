import { keyLockr_ssoInit, model_user } from './sso-init';
import { Modal } from 'bootstrap';
import { toast } from 'react-toastify';
import { ENV } from '../utils/globals';
import { api_callAxios } from '../api/utils';

export function loginLogic(qrcode_modal_id: string) {
	if (model_user.s.user_id) {
		console.log('User is already logged in.', model_user.s);
		return;
	}

	if (ENV === 'dev') {
		console.log('Calling sso_save in as ENV=dev');
		api_callAxios('/sso_save?token=12024', 'get', null, null);
		return;
	}

	const onSuccess = () => {
		closeQRCodeDialog(qrcode_modal_id);
		window.location.assign('/');
	};

	const setSsoLink = (sso_link: string) => {
		model_user.setProperty({ sso_link: sso_link });
	};

	const onFail = (msg: string) => {
		toast.warn('Please close QR Code popup and try login again.');
	};

	keyLockr_ssoInit(setSsoLink, onSuccess, onFail);

	openQRCodeDialog(qrcode_modal_id);
}

function openQRCodeDialog(qrcode_modal_id: string) {
	const element: Element | null = document.getElementById(qrcode_modal_id);
	if (!element) {
		toast.warn('Can not find QR Code popup element');
		return;
	}

	const modal = new Modal(element);
	modal.show();
}

function closeQRCodeDialog(qrcode_modal_id: string) {
	const element: Element | null = document.getElementById(qrcode_modal_id);
	if (!element) {
		toast.warn('Can not find QR code popup element');
		return;
	}
	const modal = new Modal(element);
	modal.hide();
}
