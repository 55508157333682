import { model_user } from '../keylockr/sso-init';
import { TUrl } from './types';
import { api_callAxios } from './utils';

export async function api_loadUrlList(): Promise<TUrl[]> {
	console.log('api_loadUrlList');
	if (!model_user.s.user_id) return [];
	const respData = await api_callAxios(`/url/list`, 'post', null, null);
	return respData.list as TUrl[];
}
