import { model_user } from '../keylockr/sso-init';
import { TDomain } from './types';
import { api_callAxios } from './utils';

export async function api_loadPrivDomainList(): Promise<TDomain[]> {
	console.log('api_loadPrivDomainList');

	if (!model_user.s.user_id) {
		console.warn('No logged in user');
		return [];
	}
	const respData = await api_callAxios(`/domain/list`, 'post', null, null);
	return respData.list as TDomain[];
}
