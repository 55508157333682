import { ReactModel } from 'df-react-model/dist/react-model/react-model';
import './toggle.scss';
import 'bootstrap/dist/css/bootstrap.css';

import styles from './edit.module.scss';

import clsx from 'clsx';
import { TModelUrl } from '../pages/edit/edit';

export function Geo(props: { model_url: ReactModel<TModelUrl> }) {
	const { model_url } = props;
	model_url.useModel();
	// console.log(model_url.s);
	let rows;
	if (model_url.s.geo_list != null && model_url.s.geo_list.length > 0) {
		rows = model_url.s.geo_list.map((row, rowIdx) => {
			return (
				<tr>
					<td>{row.country}</td>
					<td>{row.clicks}</td>
				</tr>
			);
		});
	}
	return (
		<div className={styles.editor_container}>
			<table className={clsx('table table-striped table-hover', styles.url_table_bootstrap)}>
				<thead>
					<tr>
						<th>Country</th>
						<th>Clicks</th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</table>
		</div>
	);
}
