import { model_user } from '../keylockr/sso-init';
import { newErrBadResponse, newErrNoUser } from '../utils/errors';
import { api_callAxios } from './utils';

export async function api_loadUser() {
	console.log('api_loadUser');
	try {
		const respData = await api_callAxios('/u/info', 'post', null, null);

		if (!respData.hasOwnProperty('_res') || respData._res !== 'ok') {
			throw newErrBadResponse(respData.code ? respData.code : 'Bad response for user info call');
		}

		if (respData.user_nickname && respData.user_id) {
			model_user.setProperty({
				user_id: respData.user_id,
				user_nickname: respData.user_nickname,
			});
		}
	} catch (e) {
		console.error((e as Error).message);
		throw newErrNoUser();
	}
}
