import { api_callAxios } from './utils';

export async function api_deleteUrl(id: string, hash: string) {
	const body = {
		hash: hash,
	};
	const respData = await api_callAxios(`/url/delete/${id}`, 'post', body, null);

	if (!(respData as Object).hasOwnProperty('_res') || respData._res !== 'ok') {
		throw Error(respData.code ? respData.code : `Failed to add url.`);
	}
}
