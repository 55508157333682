import { newErrBadResponse } from '../utils/errors';
import { TDomain } from './types';
import { api_callAxios } from './utils';
import { ReactModel } from 'df-react-model/dist/react-model/react-model';

export async function api_addDomain(model_domain: ReactModel<TDomain>) {
	const data = {
		domain: model_domain.s.domain,
		user_ids: model_domain.s.user_ids,
	};
	const respData = await api_callAxios(`/domain/add`, 'post', data, null);

	if (!(respData as Object).hasOwnProperty('_res') || respData._res !== 'ok') {
		throw newErrBadResponse(respData.code ? respData.code : `Failed to save domain settings.`);
	}
	return respData.id;
}
