import { newErrBadResponse } from '../utils/errors';
import { sanitizeSlug } from '../utils/url_helpers';
import { api_callAxios } from './utils';
import { ReactModel } from 'df-react-model/dist/react-model/react-model';
import { TModelUrlEditable } from '../components/editor';

export async function api_editUrl(model_url_editable: ReactModel<TModelUrlEditable>): Promise<TModelUrlEditable> {
	const sanitizedSlug = model_url_editable.s.slug ? sanitizeSlug(model_url_editable.s.slug) : '';
	if (sanitizedSlug === '') {
		throw Error('Slug contains invalid character(s).');
	}

	const respData = await api_callAxios(`/url/edit/${model_url_editable.s.id}`, 'post', model_url_editable.s, null);

	if (!(respData as Object).hasOwnProperty('_res') || respData._res !== 'ok') {
		throw newErrBadResponse(respData.code ? respData.code : `Failed to update url`);
	}

	const copy = { ...respData };
	delete copy._res;

	return copy as TModelUrlEditable;
}
