import { ReactModel } from 'df-react-model/dist/react-model/react-model';

import styles from './edit.module.scss';

import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { NavBar } from '../../components/navbar';
import { useRouteTo } from '../..';
import { TDomain } from '../../api/types';
import { HInput } from 'df-react-model/dist/react-model-ui/form/h-input';
import { api_editDomain } from '../../api/domain-edit';
import { api_deleteDomain } from '../../api/domain-delete';
import { model_priv_domain_list } from '../../models/domain';

//----------------------------------------------------------------
// Model
//----------------------------------------------------------------

export const model_domain = new ReactModel<TDomain>({
	model: {
		id: '',
		atime: '',
		mtime: '',
		domain: '',
		user_ids: '',
		redirection_method: 0,
		logo: '',
	},
});

//----------------------------------------------------------------
// View
//----------------------------------------------------------------
export function DomainEditContainer() {
	const [searchParams] = useSearchParams();
	const routeTo = useRouteTo();

	model_priv_domain_list.useModel();

	const domain_id = searchParams.get('id');
	const curr_domain = model_priv_domain_list.s.list.find((domain) => domain.id == domain_id);
	console.log('model_priv_domain_list', model_priv_domain_list.s.list, 'domain_id', domain_id, 'current domain', curr_domain);
	model_domain.setProperty({
		...curr_domain,
	});

	const updateData = async () => {
		const formData = new FormData();
		formData.append('id', model_domain.s.id);
		formData.append('user_ids', model_domain.s.user_ids);

		if (model_domain.s.domain !== curr_domain?.domain) {
			formData.append('new_domain', model_domain.s.domain);
		}

		const logoInput = document.querySelector('input[name="logo"]') as HTMLInputElement | null;

		if (logoInput?.files && logoInput.files.length > 0) {
			// Add the selected file to the FormData
			const logoFile = logoInput.files[0];
			formData.append('logo', logoFile);
		}

		// Check if the "delete logo" checkbox is checked
		const deleteLogoCheckbox = document.querySelector('input[name="deleteLogo"]') as HTMLInputElement | null;
		if (deleteLogoCheckbox?.checked) {
			formData.append('delete_logo', 'true'); // Add flag to delete the logo
		}

		const redirectionMethod = +(document.querySelector('input[name="redirection_method"]:checked') as HTMLInputElement)?.value;
		if (redirectionMethod) {
			formData.append('redirection_method', redirectionMethod.toString());
		}

		let logo = '';
		try {
			logo = await api_editDomain(formData);
		} catch (e) {
			if (!(e instanceof Error)) {
				toast.warn('[E193] An internal error occurred, please refresh page.');
				return;
			}
			toast.warn(e.message);
		}

		// Update model data
		model_domain.setProperty({ logo, redirection_method: redirectionMethod });
		let copy = model_priv_domain_list.s.list.map((domain) =>
			domain.id === model_domain.s.id ? { ...domain, domain: model_domain.s.domain, user_ids: model_domain.s.user_ids, logo, redirection_method: redirectionMethod } : domain
		);
		model_priv_domain_list.setProperty({ list: copy });
		toast.success('Data updated.');
	};

	return (
		<>
			<NavBar />

			<div className="d-flex justify-content-center">
				<div>
					<div className="row mt-3">
						<div className="col">
							<button className="nav-link align-middle px-0" onClick={(e) => routeTo(e, `/x/domain`)}>
								<i className="fa fa-arrow-left me-1"></i>
								Back
							</button>
						</div>
					</div>

					<div className="row">
						<div className="col py-3">
							<h2>Domain Edit</h2>
							<div className={styles.editor_container}>
								<label>Domain</label>
								<HInput model={model_domain} field="domain" />

								<label>User Ids</label>
								<HInput model={model_domain} field="user_ids" />

								<label>QR Code central Logo</label>
								{model_domain.s.logo && <img src={model_domain.s.logo} alt="logo" />}
								<input type="file" name="logo" />

								<div className="form-check ms-auto">
									<input type="checkbox" className="form-check-input" name="deleteLogo" id="deleteLogoCheckbox" />
									<label className="form-check-label ms-3 btn btn-outline-danger btn-sm mt-2" htmlFor="deleteLogoCheckbox">
										Delete Logo
									</label>
								</div>

								<label>Redirection Method</label>

								<div className="btn-group" role="group" aria-label="Basic radio toggle button group">
									<input
										type="radio"
										className="btn-check"
										name="redirection_method"
										id="btnradio1"
										value="0"
										defaultChecked={model_domain.s.redirection_method === 0}
									/>
									<label className="btn btn-outline-primary" htmlFor="btnradio1">
										Landing page
									</label>

									<input
										type="radio"
										className="btn-check"
										name="redirection_method"
										id="btnradio2"
										value="1"
										defaultChecked={model_domain.s.redirection_method === 1}
									/>
									<label className="btn btn-outline-primary" htmlFor="btnradio2">
										Blank landing page w/ loading
									</label>

									<input
										type="radio"
										className="btn-check"
										name="redirection_method"
										id="btnradio3"
										value="2"
										defaultChecked={model_domain.s.redirection_method === 2}
									/>
									<label className="btn btn-outline-primary" htmlFor="btnradio3">
										Direct redirection
									</label>
								</div>

								<button className="btn btn-primary mt-5" onClick={updateData}>
									Submit Changes
								</button>
							</div>
						</div>
					</div>
					<div className="row mt-5">
						<div className="col py-3">
							<DeleteButton id={model_domain.s.id} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

function DeleteButton(props: { id: string }): JSX.Element {
	const { id } = props;
	const deleteItem = async () => {
		const userConfirmed = window.confirm('Are you sure you want to delete this domain? This action cannot be undone.');

		if (!userConfirmed) {
			return; // Exit if the user cancels
		}

		try {
			await api_deleteDomain(id);
			let copy: TDomain[] = Object.assign([], model_priv_domain_list.s.list);
			const index = copy.findIndex((item) => item.id == id);
			copy.splice(index, 1);
			model_priv_domain_list.setProperty({ list: copy });
		} catch (e) {
			if (e instanceof Error) {
				toast.warn(e.message);
				return;
			}
			toast.warn('Failed to delete item');
		}
	};

	return (
		<button onClick={deleteItem} className="ms-3 btn btn-danger btn-sm">
			<i className="fa-solid fa-trash-can"></i>
		</button>
	);
}
