import { api_callAxios } from './utils';

export async function api_deleteDomain(id: string) {
	const body = {};
	const respData = await api_callAxios(`/domain/delete/${id}`, 'post', body, null);

	if (!(respData as Object).hasOwnProperty('_res') || respData._res !== 'ok') {
		throw Error(respData.code ? respData.code : `Failed to del domain.`);
	}
}
