import { ReactModel } from 'df-react-model/dist/react-model/react-model';

import { api_loadUrlList } from '../api/url-list';
import { TUrl } from '../api/types';

type TModelUrlList = {
	list: TUrl[];
};

export const model_url_list = new ReactModel<TModelUrlList>({
	model: {
		list: [],
	},
});

export async function loadUrlList() {
	const url_list = await api_loadUrlList();
	model_url_list.setProperty({
		list: url_list ?? [],
	});
}
