import 'bootstrap/dist/css/bootstrap.css';

import { useEffect } from 'react';
import { NavBar } from '../../components/navbar';
import { toast } from 'react-toastify';
import { useRouteTo } from '../..';
import { loadPrivDomainList, model_priv_domain_list } from '../../models/domain';

//----------------------------------------------------------------
// Model
//----------------------------------------------------------------

//----------------------------------------------------------------
// Controller
//----------------------------------------------------------------

async function init() {
	try {
		await loadPrivDomainList();
	} catch (e) {
		if (!(e instanceof Error)) {
			toast.warn('Failed to load domain list');
			return;
		}
		toast.warn(e.message);
	}
}

//----------------------------------------------------------------
// View
//----------------------------------------------------------------

/**
 * Entry point.
 */
export function DomainContainer() {
	return (
		<>
			<NavBar />
			<div className="d-flex flex-column justify-content-center align-items-center">
				<div className="d-flex">
					<h2>Domain Management</h2>
					<div>
						<a href="/x/domain_add" className="ms-5 btn btn-primary btn-sm">
							<i className="fa-solid fa-plus"></i> Add
						</a>
					</div>
				</div>

				<table className="table table-striped table-hover">
					<thead>
						<tr>
							<th>Id</th>
							<th>Domain</th>
							<th>Redirection Method</th>
							<th>QR Code Logo</th>
							<th>User Ids</th>
							<th>Action</th>
							<th>Added</th>
							<th>Modifed</th>
						</tr>
					</thead>
					<tbody>
						<Rows />
					</tbody>
				</table>
			</div>
		</>
	);
}

const sanitizeDisplayTime = (time: string): string => {
	const times = time.split(' ');
	return times[0];
};

function Rows() {
	useEffect(() => {
		init();
	}, []);

	const routeTo = useRouteTo();
	model_priv_domain_list.useModel();
	let rows = model_priv_domain_list.s.list.map((row, rowIdx) => {
		return (
			<tr key={row.id}>
				<td>{row.id}</td>
				<td>{row.domain}</td>
				<td>
					{row.redirection_method == 0 && <span className="text-primary">Landing page</span>}
					{row.redirection_method == 1 && <span className="text-warning">Blank landing page w/ loading</span>}
					{row.redirection_method == 2 && <span className="text-danger">Direct redirection</span>}
				</td>
				<td>{row.logo && <img src={row.logo} alt="logo" />}</td>
				<td>{row.user_ids}</td>
				<td>
					<button onClick={(e) => routeTo(e, `/x/domain_edit?id=${row.id}`)} className="btn btn-primary btn-sm">
						<i className="fa-regular fa-pen-to-square"></i>
					</button>
				</td>
				<td>{sanitizeDisplayTime(row.atime)}</td>
				<td>{sanitizeDisplayTime(row.mtime)}</td>
			</tr>
		);
	});

	return <>{rows}</>;
}
