import { ReactModel } from 'df-react-model/dist/react-model/react-model';

import styles from './edit.module.scss';

import { toast } from 'react-toastify';
import { NavBar } from '../../components/navbar';
import { useRouteTo } from '../..';
import { TDomain } from '../../api/types';
import { HInput } from 'df-react-model/dist/react-model-ui/form/h-input';
import { api_addDomain } from '../../api/domain-add';
import { model_priv_domain_list } from '../../models/domain';

//----------------------------------------------------------------
// Model
//----------------------------------------------------------------

const model_add_domain = new ReactModel<TDomain>({
	model: {
		id: '',
		atime: '',
		mtime: '',
		domain: '',
		user_ids: '',
		redirection_method: 0,
		logo: '',
	},
});

//----------------------------------------------------------------
// View
//----------------------------------------------------------------
export function DomainAddContainer() {
	const routeTo = useRouteTo();
	model_add_domain.useModel();

	const addData = async () => {
		try {
			const id = await api_addDomain(model_add_domain);

			// Update model data
			const copy = [...model_priv_domain_list.s.list];
			model_add_domain.setProperty({ id });
			copy.unshift(model_add_domain.s);
			model_priv_domain_list.setProperty({ list: copy });
			toast.success('Domain added.');
			routeTo(false, '/x/domain');
		} catch (e) {
			if (!(e instanceof Error)) {
				toast.warn('[E193] An internal error occurred, please refresh page.');
				return;
			}
			toast.warn(e.message);
		}
	};

	return (
		<>
			<NavBar />

			<div className="d-flex justify-content-center">
				<div>
					<div className="row mt-3">
						<div className="col">
							<button className="nav-link align-middle px-0" onClick={(e) => routeTo(e, `/x/domain`)}>
								<i className="fa fa-arrow-left me-1"></i>
								Back
							</button>
						</div>
					</div>

					<div className="row">
						<div className="col py-3">
							<h2>Domain Add</h2>
							<div className={styles.editor_container}>
								<label>Domain</label>
								<HInput model={model_add_domain} field="domain" />

								<label>User Ids</label>
								<HInput model={model_add_domain} field="user_ids" />

								<button className="btn btn-primary" onClick={addData}>
									Submit Changes
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
