import { ReactModel } from 'df-react-model/dist/react-model/react-model';
import './toggle.scss';
import 'bootstrap/dist/css/bootstrap.css';

import styles from './edit.module.scss';

import { TModelUrl } from '../pages/edit/edit';
import { QRCodeSVG } from 'qrcode.react';
import { useCallback, useEffect, useState } from 'react';
import { model_domain_list } from './navbar';

export function Qrcode(props: { model_url: ReactModel<TModelUrl> }) {
	const { model_url } = props;
	model_url.useModel();

	const [qrCodeImage, setQrCodeImage] = useState<string | null>(null);

	const logoUrl = model_domain_list.s.list.find((x) => x.id == model_url.s.domain_id)?.logo;
	const generateQrCodeWithLogo = useCallback(() => {
		console.log('create image for download');
		// Create a canvas to draw the SVG content on
		const canvas = document.createElement('canvas');
		const svgElement = document.querySelector('.qr-code svg');

		if (svgElement) {
			const svgData = new XMLSerializer().serializeToString(svgElement);
			const image = new Image();
			const svgBlob = new Blob([svgData], { type: 'image/svg+xml' });
			const url = URL.createObjectURL(svgBlob);

			image.onload = function () {
				const ctx = canvas.getContext('2d');
				canvas.width = image.width;
				canvas.height = image.height;
				ctx?.drawImage(image, 0, 0);

				if (logoUrl) {
					// Load and draw the logo
					const logo = new Image();
					logo.onload = function () {
						const logoSize = canvas.width * 0.2; // Logo size as 20% of QR code
						const x = (canvas.width - logoSize) / 2; // Center the logo
						const y = (canvas.height - logoSize) / 2; // Center the logo

						// Draw the logo on top of the QR code
						ctx?.drawImage(logo, x, y, logoSize, logoSize);

						// Now we have the QR code with the logo as an image on the canvas, we can convert it to JPG
						const dataUrl = canvas.toDataURL('image/jpeg'); // JPEG format
						setQrCodeImage(dataUrl);
						URL.revokeObjectURL(url); // Clean up the URL object
					};
					logo.src = logoUrl + `?t=${new Date().getTime()}`; // Load the logo
				} else {
					// If no logoUrl, proceed without logo
					const dataUrl = canvas.toDataURL('image/jpeg'); // JPEG format
					setQrCodeImage(dataUrl);
					URL.revokeObjectURL(url); // Clean up the URL object
				}
			};
			image.src = url; // Start loading the SVG image into the <img> element
		}
	}, [logoUrl]);

	useEffect(() => {
		generateQrCodeWithLogo();
	}, [generateQrCodeWithLogo]);

	const handleDownloadImage = () => {
		if (qrCodeImage) {
			const a = document.createElement('a');
			a.href = qrCodeImage;
			a.download = `qrcode.${model_url.s.id}.png`;
			a.click();
			console.log('Downloading QR Code with logo...');
		}
	};

	return (
		<div className={styles.editor_container}>
			<div className="qr-code m-3" style={{ position: 'relative', display: 'inline-block' }}>
				{qrCodeImage ? (
					<img src={qrCodeImage} alt="QR Code with Logo" style={{ border: '3px solid white', width: '256px', height: '256px' }} />
				) : (
					<QRCodeSVG style={{ border: '3px solid white' }} value={model_url.s.okurl} size={256} />
				)}
			</div>
			<button className="btn btn-success btn-lg" onClick={handleDownloadImage}>
				Download QR Code
			</button>
		</div>
	);
}
