import { ReactModel } from 'df-react-model/dist/react-model/react-model';
import { TDomain } from '../api/types';
import { api_loadPrivDomainList } from '../api/domain-list-priv';

type TModelDomainList = {
	list: TDomain[];
};

export const model_priv_domain_list = new ReactModel<TModelDomainList>({
	model: {
		list: [],
	},
});

export async function loadPrivDomainList() {
	const list = await api_loadPrivDomainList();
	model_priv_domain_list.setProperty({
		list: list ?? [],
	});
}
