import { newErrBadResponse } from '../utils/errors';
import { sanitizeUrl } from '../utils/url_helpers';
import { api_callAxios } from './utils';
import { TRouteTo } from '..';
import { model_url } from '../pages/edit/edit';
import { toast } from 'react-toastify';
import { model_url_list } from '../models/url';

export type TNewUrl = {
	domain_id: string;
	url: string;
	slug: string;
	pswd: string;
	expiry: string;
};

export const api_submitNewUrl = async (url: string, selected: any, routeTo: TRouteTo) => {
	const sanitzedUrl = sanitizeUrl(url);

	if (sanitzedUrl === '') {
		throw Error('Invalid URL.');
	}

	const data: TNewUrl = {
		domain_id: selected?.id,
		url: sanitzedUrl,
		slug: '',
		pswd: '',
		expiry: '',
	};
	const respData = await api_callAxios('/url/add', 'post', data, null);

	if (!(respData as Object).hasOwnProperty('_res') || respData._res !== 'ok') {
		throw newErrBadResponse(respData.code ? respData.code : `Failed to add url.`);
	}

	delete respData._res;

	const copy = [...model_url_list.s.list];
	copy.unshift(respData);
	model_url_list.setProperty({ list: copy });
	model_url.setProperty({
		...respData,
	});
	toast.success('New url added successfully');
	routeTo(false, `/x/edit?id=${respData.id}`);
};
