import { ReactModel } from 'df-react-model/dist/react-model/react-model';
import './toggle.scss';
import { HInput } from 'df-react-model/dist/react-model-ui/form/h-input';

import styles from './edit.module.scss';

import { toast } from 'react-toastify';
import { api_editUrl } from '../api/url-edit';
import { model_url } from '../pages/edit/edit';

export const model_url_editable = new ReactModel<TModelUrlEditable>({
	model: {
		id: '',
		url: '', // original url
		slug: '',
		title: '',
		pswd: '',
		expiry: '',
	},
});

export type TModelUrlEditable = {
	id: string;
	url: string; // original url
	slug: string;
	title: string;
	pswd: string;
	expiry: string;
};

export function Editor(props: { model_url_editable: ReactModel<TModelUrlEditable> }) {
	const { model_url_editable } = props;
	model_url_editable.useModel();

	const updateUrlData = async () => {
		try {
			const edited = await api_editUrl(model_url_editable);
			model_url.setProperty({
				...edited,
			});
			toast.success('Data updated.');
			// navigate?.(`/`, { state: {} });
		} catch (e) {
			if (!(e instanceof Error)) {
				toast.warn('[E193] An internal error occurred, please refresh page.');
				return;
			}
			toast.warn(e.message);
		}
	};

	return (
		<div className={styles.editor_container}>
			<label>Title</label>
			<HInput
				model={model_url_editable}
				field="title"
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						updateUrlData();
					}
				}}
			/>

			<label>Target URL</label>
			<HInput
				model={model_url_editable}
				field="url"
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						updateUrlData();
					}
				}}
			/>

			<label>Slug</label>
			<HInput
				model={model_url_editable}
				field="slug"
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						updateUrlData();
					}
				}}
			/>

			<label>Password Protection</label>
			<HInput
				model={model_url_editable}
				field="pswd"
				onKeyDown={(e) => {
					if (e.key === 'Enter') {
						updateUrlData();
					}
				}}
			/>

			<button className="btn btn-primary" onClick={updateUrlData}>
				Submit Changes
			</button>
			<div className="text-secondary mt-3">
				<i className="text-warning m-3 fa-regular fa-lightbulb"></i> Can use "Enter"(↵) to submit
			</div>
		</div>
	);
}
