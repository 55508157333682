import { ReactModel } from 'df-react-model/dist/react-model/react-model';

import styles from './edit.module.scss';

import { SyntheticEvent, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api_loadUrlDetail } from '../../api/url-detail';
import { NavBar } from '../../components/navbar';
import { Editor, model_url_editable } from '../../components/editor';
import { Geo } from '../../components/geo';
import { Qrcode } from '../../components/qrcode';
import { useRouteTo } from '../..';

//----------------------------------------------------------------
// Model
//----------------------------------------------------------------
type TSidebar = 'edit' | 'geo' | 'qrcode';
type TModelCurrSidebar = { curr: TSidebar };
const model_menu = new ReactModel<TModelCurrSidebar>({ model: { curr: 'edit' } });

export const model_url = new ReactModel<TModelUrl>({
	model: {
		id: '',
		atime: '',
		mtime: '',
		domain_id: '',
		url: '', // original url
		slug: '',
		title: '',
		pswd: '',
		expiry: '',
		hash: '',
		okurl: '',
		clicks: '',
		geo_list: [],
	},
});

export type TModelUrl = {
	id: string;
	atime: string;
	mtime: string;
	domain_id: string;
	url: string; // original url
	slug: string;
	title: string;
	pswd: string;
	expiry: string;
	hash: string;
	okurl: string;
	clicks: string;
	geo_list: {
		country: string;
		clicks: string;
	}[];
};

let curr_id = '';

//----------------------------------------------------------------
// Controller
//----------------------------------------------------------------
function copyUrl(model: ReactModel<TModelUrl>) {
	navigator.clipboard.writeText(model.s.okurl);
	toast.success('Copied URL.');
}

//----------------------------------------------------------------
// View
//----------------------------------------------------------------
export function EditPageContainer() {
	const [searchParams] = useSearchParams();
	const routeTo = useRouteTo();
	useEffect(() => {
		init();
	});

	function routeToDetailTab(e: SyntheticEvent, id: string, curr: TSidebar) {
		console.log('route to ' + curr);
		model_menu.setProperty({ curr });
		routeTo(e, `/x/edit?id=${id}&curr=${curr}`);
	}

	async function init() {
		const url_id = searchParams.get('id');
		const curr = searchParams.get('curr') as TSidebar;
		if (curr) model_menu.setProperty({ curr });

		try {
			if (!url_id) {
				toast.warn('no id');
				return;
			}

			if (curr_id !== url_id) {
				const resp_url = await api_loadUrlDetail(url_id);
				model_url.setProperty({
					...resp_url,
				});
				model_url_editable.setProperty({
					id: resp_url.id,
					url: resp_url.url,
					slug: resp_url.slug,
					title: resp_url.title,
					pswd: resp_url.pswd,
					expiry: resp_url.expiry,
				});
				curr_id = url_id;
			}
		} catch (e) {
			if (e instanceof Error) {
				toast.warn(e.message);
			} else {
				toast.warn('An unknown error occurred.');
			}
		}
	}

	model_url.useModel();
	model_menu.useModel();

	return (
		<>
			<NavBar />

			<div className={styles.edit_page_container}>
				<div className={styles.edit_page_body}>
					<div className="row mt-3">
						<div className="col-md-3 col-xl-2 text-center">
							<button className="nav-link align-middle px-0" onClick={(e) => routeTo(e, `/`)}>
								<i className="fa fa-arrow-left me-1"></i>
								Back
							</button>
						</div>
						<div className="col">
							<div className="">
								<code className="me-3 fw-bold text-success">{model_url.s.okurl}</code>
								<button onClick={() => copyUrl(model_url)} className="btn btn-success btn-sm">
									<i className="fa fa-copy me-1"></i>
									Copy
								</button>
							</div>
							<div className="">
								<i className="fa-solid fa-link me-1"></i> <code className="text-secondary">{model_url.s.url}</code>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-3 col-xl-2 px-sm-2 px-0">
							<div className="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
								<ul className="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start" id="menu">
									<li className="nav-item">
										<button className="nav-link align-middle px-0" onClick={(e) => routeToDetailTab(e, model_url.s.id, 'edit')}>
											<i className="fa-solid fa-gear me-1"></i>
											<span className="ms-1 d-none d-sm-inline">Basic Settings</span>
										</button>
										<button className="nav-link align-middle px-0" onClick={(e) => routeToDetailTab(e, model_url.s.id, 'geo')}>
											<i className="fa-solid fa-location-dot me-1"></i>
											<span className="ms-1 d-none d-sm-inline">Geo Counts</span>
										</button>
										<button className="nav-link align-middle px-0" onClick={(e) => routeToDetailTab(e, model_url.s.id, 'qrcode')}>
											<i className="fa-solid fa-qrcode me-1"></i>
											<span className="ms-1 d-none d-sm-inline">QR Code</span>
										</button>
									</li>
								</ul>
							</div>
						</div>
						<div className="col py-3">
							<div className={styles.title_container}>
								<h2>
									{model_menu.s.curr === 'edit' ? 'Basic Settings' : ''}
									{model_menu.s.curr === 'geo' ? 'Geo Location Analytics' : ''}
									{model_menu.s.curr === 'qrcode' ? 'QR Code' : ''}
								</h2>
							</div>
							{model_menu.s.curr === 'edit' ? <Editor model_url_editable={model_url_editable} /> : ''}
							{model_menu.s.curr === 'geo' ? <Geo model_url={model_url} /> : ''}
							{model_menu.s.curr === 'qrcode' ? <Qrcode model_url={model_url} /> : ''}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
