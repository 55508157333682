import axios from 'axios';
import nacl from 'tweetnacl';

// TODO: Prepare for npm module.
import { cfg_Sso_name } from './cfg-keylockr';
import { ReactModel } from 'df-react-model/dist/react-model/react-model';

export type TKeyLockr_UserInfo = {
	user_id: string;
	user_nickname: string;
	sso_link?: string;
};

export const model_user = new ReactModel<TKeyLockr_UserInfo>({
	model: {
		user_id: '',
		user_nickname: '',
		sso_link: '',
	},
});

export type TKeyLockr_HandshakeData = {
	name: string;
	sign_pk: Uint8Array | number[];
	encrypt_pk: Uint8Array | number[];
};

export type TKeyLockr_SetSsoContent = (content: string) => void;
export type TKeyLockr_OnSuccessFunc = () => void;
export type TKeyLockr_OnFailureFunc = (msg: string) => void;

export type TKeyLockr_QrCodeContent = string;

/**
 * This function registers the websocket, returns the qr code content, and the
 * callbacks take care of the rest.
 */
export async function keyLockr_ssoInit(setSsoLink: TKeyLockr_SetSsoContent, onSuccess: TKeyLockr_OnSuccessFunc, onFailure: TKeyLockr_OnFailureFunc) {
	websocketHandler(setSsoLink, onSuccess, onFailure);
}

async function websocketHandler(setSsoLink: TKeyLockr_SetSsoContent, onSuccess: TKeyLockr_OnSuccessFunc, onFailure: TKeyLockr_OnFailureFunc) {
	const conn = new WebSocket(`wss://${window.location.host}/sso_init`);

	conn.onclose = function (evt) {
		onFailure('[KLSI14] Websocket connection closed.');
	};

	conn.onerror = function (error) {
		onFailure(`[KLSI14] Websocket connection closed. error: ${error}`);
	};

	conn.onmessage = function (evt) {
		try {
			const data = JSON.parse(evt.data);
			if (data.hasOwnProperty('token')) {
				axios.get('/sso_save?token=' + data.token);
				onSuccess();
			} else if (data.hasOwnProperty('qrcode')) {
				setSsoLink(data.qrcode);
			}
		} catch (e) {
			console.error(`[KLSI58] Failed to handle message. error: ${e}, data: ${evt.data}`);
		}
	};

	conn.onopen = function () {
		const key_pairs = sso_generateKeyPairs();
		const data: TKeyLockr_HandshakeData = {
			name: cfg_Sso_name,
			sign_pk: Array.from(key_pairs.sign_pk),
			encrypt_pk: Array.from(key_pairs.encrypt_pk),
		};

		conn.send(JSON.stringify(data));
	};
}

function sso_generateKeyPairs() {
	const { publicKey: encrypt_pk, secretKey: encrypt_sk } = nacl.box.keyPair();
	const { publicKey: sign_pk, secretKey: sign_sk } = nacl.sign.keyPair();
	return { encrypt_sk, encrypt_pk, sign_sk, sign_pk };
}
