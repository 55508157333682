import 'bootstrap/dist/css/bootstrap.css';

import { useEffect } from 'react';
import { NavBar } from '../../components/navbar';
import { TUrl } from '../../api/types';
import { api_deleteUrl } from '../../api/url-delete';
import { toast } from 'react-toastify';
import { useRouteTo } from '../..';
import { loadUrlList, model_url_list } from '../../models/url';

async function init() {
	try {
		await loadUrlList();
	} catch (e) {
		if (!(e instanceof Error)) {
			toast.warn('Failed to load url list');
			return;
		}

		toast.warn(e.message);
	}
}

//----------------------------------------------------------------
// View
//----------------------------------------------------------------

/**
 * Entry point.
 */
export function HomePageContainer() {
	const routeTo = useRouteTo();

	useEffect(() => {
		init();
	}, []);

	return (
		<>
			<NavBar />

			<div>
				<h2>Welcome</h2>

				<UrlTable />
			</div>
		</>
	);

	function EditButton(props: { id: string }): JSX.Element {
		return (
			<button className="btn btn-primary btn-sm" onClick={(e) => routeTo(e, `/x/edit?id=${props.id}`)}>
				<i className="fa-regular fa-pen-to-square"></i>
			</button>
		);
	}

	function UrlTable(): JSX.Element {
		const head = (
			<thead>
				<tr>
					<th>Id</th>
					<th>OKUrl</th>
					<th>Title / Link</th>
					<th>Clicks</th>
					<th>
						<div>Password</div>
					</th>
					<th>Expiry</th>
					<th>Action</th>
					<th>Added</th>
					<th>Modifed</th>
				</tr>
			</thead>
		);

		model_url_list.useModel();
		const u = model_url_list.s.list;
		// const u = model_url_list.useModel('list').state; // TODO: see if need this way or not
		if (u === undefined) {
			return <></>;
		}
		let rows = u.map((row, rowIdx) => {
			return <TableRow {...{ rowIdx, row }} />;
		});

		return (
			<table className="table table-striped table-hover border">
				{head}
				<tbody>{rows}</tbody>
			</table>
		);
	}

	function TableRow(props: { rowIdx: number; row: TUrl }) {
		const { row } = props;
		return (
			<tr key={row.id}>
				<td>{row.id}</td>
				<td>
					<div className="d-flex justify-content-between">
						<span className="me-3">{row.okurl}</span>
						{<EditButton {...row} />}
					</div>
				</td>
				<td className="text-break" style={{ maxWidth: '30vw' }}>
					{row.title ?? ''}
					<i className="fa-solid fa-link me-1"></i>
					<a target="_blank" className="fs-6" rel="noopener noreferrer" href={row.url}>
						{row.url}
					</a>
				</td>
				<td>{row.clicks}</td>
				<td>
					<div>{passwordDisplay(row.pswd)}</div>
				</td>
				<td>
					<div>{row.expiry}</div>
				</td>
				<td>
					<DeleteButton {...row} />
				</td>
				<td>{sanitizeDisplayTime(row.atime)}</td>
				<td>{sanitizeDisplayTime(row.mtime)}</td>
			</tr>
		);
	}
}

function DeleteButton(props: { id: string; hash: string }): JSX.Element {
	const { id, hash } = props;
	const deleteItem = async () => {
		try {
			await api_deleteUrl(id, hash);
			let copy: TUrl[] = Object.assign([], model_url_list.s.list);
			const index = copy.findIndex((item) => item.id === id);
			copy.splice(index, 1);
			model_url_list.setProperty({ list: copy });
		} catch (e) {
			if (e instanceof Error) {
				toast.warn(e.message);
				return;
			}
			toast.warn('Failed to delete item');
		}
	};

	return (
		<button onClick={deleteItem} className="btn btn-danger ms-3 btn-sm">
			<i className="fa-solid fa-trash-can"></i>
		</button>
	);
}

function passwordDisplay(p: string): string {
	if (p.length === 0) {
		return '';
	} else {
		return p;
	}
}

const sanitizeDisplayTime = (time: string): string => {
	const times = time.split(' ');
	return times[0];
};
