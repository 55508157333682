import { newErrBadResponse } from '../utils/errors';
import { api_callAxios } from './utils';

export async function api_editDomain(formData: FormData) {
	const respData = await api_callAxios(`/domain/edit`, 'post', formData, null);

	if (!(respData as Object).hasOwnProperty('_res') || respData._res !== 'ok') {
		throw newErrBadResponse(respData.code ? respData.code : `Failed to save domain settings.`);
	}
	return respData.logo;
}
