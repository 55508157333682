import axios from 'axios';
import { BE_EP, MOCK_MODE } from '../utils/globals';

export async function api_callAxios(url: string, method: string, data: any, mock_data: any): Promise<any> {
	if (MOCK_MODE) {
		return mock_data;
	}

	const headers: Record<string, string> = {
		Accept: 'application/json',
	};

	// If data is a FormData instance, set the Content-Type to multipart/form-data
	if (data instanceof FormData) {
		headers['Content-Type'] = 'multipart/form-data';
	}

	const resp = await axios.request({
		url: BE_EP + url,
		method,
		data,
		headers,
		withCredentials: true,
	});
	return resp.data;
}
